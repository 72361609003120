.landing-page-container {



    text-align: center;
    border-left: 10px solid var(--primary);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;



}
.landing-page-container::before {

    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 90%;
    width: 90%;
    display: block;
    background-repeat: no-repeat;
    background-position: 60% 30px;
    background-image: url("LOGO BAU.png");
    background-color: var(--background);
    background-attachment: scroll;
    background-size: contain;

    -webkit-mask-image: -webkit-gradient(linear, left top,
    left bottom, from(rgba(0, 0, 0, 3)), to(rgba(0, 0, 0, 0)));

    opacity: 0.4;



}


.welcome {


    letter-spacing: 2px;
    font-size: 34px;
    font-weight: 800;


}

.separator {

    border-bottom: 1px solid var(--primary);
    margin: 20px 200px;
}

.BAU_description {

    display: flex;
   align-items: center;

}

.saiyleon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-style: italic;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;

}

.small_logo {
    height: 30px;
    opacity: 0.4;
}

.about {

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
}

.landing-page-header {
    text-align: end;
}

.social_accounts .social-icon {
    margin-right: 15px;
}

@media (max-width: 820px) {

    .landing-page-container {
        border: none;




    }


    .landing-page-container::before {
        content: "";
        position: absolute;


        background-position: 100% 0px;
        height: 100%;
        width: 100%;
        display: block;
        background-repeat: no-repeat;


        background-image: url("LOGO-BAU-Vertical.png");
        background-color: var(--background);
        background-attachment: scroll;
        background-size: cover;

        -webkit-mask-image: -webkit-gradient(linear, left top,
        left bottom, from(rgba(0, 0, 0, 3)), to(rgba(0, 0, 0, 0)));

        opacity: 1;

    }


    .welcome {
        font-size: 26px;

    }

    .separator {
        border-bottom: 1px solid var(--primary);
        margin: 20px 50px;
    }

    .about {
        justify-content: center;
    }

    .small_logo {
        display: none;

    }

    .BAU_description {

        flex-direction: column;
        gap: 5px;
        font-size: 14px;

        text-align: center;
    }
    .saiyleon {
        height: 20px;
    }

}