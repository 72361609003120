.footer {
    padding-top: 5px;
 display: flex;
    align-items: center;
    font-size: 13px;
    justify-content: space-between;
    border-top: 2px solid #3b3b3b;
    margin-left: 20px;
    margin-right: 20px;
    color: #848484;
    line-height: 1rem;



}
.Footer_container {
    padding: 20px;

}
.Footer_title {
    font-size: 2rem;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 10px;
    margin-bottom: 20px ;
    width: fit-content;
}
.featuredContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.MoonlyTitle {
    color: rgb(167, 193, 192);
    margin-bottom: 0px;
    font-style: italic;
    margin-right: 15px;
}
.signature {
    height: 20px;
    cursor: pointer;

}
@media (max-width: 820px) {
    .footer{
        font-size: 10px;
    }
    .featuredContainer {
        margin-top: 50px;
        flex-direction: column;
        align-content: space-around;
    }
    .Footer_title {
        font-size: 24px;
    }


}