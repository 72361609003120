.address_container{
    font-size: 16px;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.contract_address {
    margin-left: 20px;
    color: var(--primary);

}
.clue_hint {
    font-size: x-small;
    color: #14161b;
   width: fit-content;

    cursor: grab;


}

.show_hint  {
    color: inherit;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    #margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    #letter-spacing: .15em; /* Adjust as needed */
    animation:
            typing 10s steps(100, end) ,
            blink-caret 1.2s step-end infinite;
}

/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 30% ; color: #14161b; }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }

}
@media (max-width: 820px) {
    .address_container {
        gap: 6px;
    }
    .contract_address {
        display: block;
        font-size: 12px;
        text-wrap: normal;
        text-align: center;
        max-width: 360px;
        margin-left: 0px;

    }
    .clue_hint {
        display: none;
    }
    .show_hint {
        display: none;
    }
}
