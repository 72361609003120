.hero_container {


    margin-top: 20px;
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 20px;


}

.hero_img {


    object-fit: cover;
    border-radius: 10px;

}

.hero_description {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    padding-top: 40px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 10px;

}
.hero_description_img {

    height: 320px;
    width: 320px;
    object-fit: cover;
    border-radius: 10px;
}
.hero_callout {
    color: white;


    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    align-items: stretch;
    gap: 10px;
    max-width: 500px;
    text-align: center;


}


.hero_title {

    font-style: italic;
    font-size: 2rem;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 20px;
   min-width: fit-content;


}

.hero_text {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: justify;

}





/* CSS */
.hero_button {
    align-items: center;
    background-clip: padding-box;
    background-color: #fa6400;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    justify-content: center;
    line-height: 1.25;
    margin-top: 20px;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width:auto;
    height: fit-content;
}
.mobile-hero {
    width: 100%;

}

.hero_button:hover,
.hero_button:focus {
    background-color: #fb8332;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.hero_button:hover {
    transform: translateY(-1px);
}

.hero_button:active {
    background-color: #c85000;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
}

@media (max-width: 820px) {
    .hero_container {

        width: 100%;
        padding-top: 10px;
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 10px;
    }
    .hero_text {
        font-size: 18px;


    }
    .mobile-hero {
        width: 100%;
        margin-top: 0px;
    }
    .hero_img {
        object-fit: contain;
        max-height : 450px;
    }
    .hero_description {
        flex-direction : column-reverse;
        padding: 10px 20px;
    }
}


