.mint_container {


    font-size: 1.2rem;



    position: relative;

}
.freemint {
    margin-left: 30px;
}

.freemintnote{
    font-size: 13px;
    width: 55%;
    margin-top: 20px;
    line-height: 15px;
}
.mint_title {
    font-size: 2rem;

    padding: 10px;

}

.market_supply {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #14161b;
    width: 100%;

    padding: 10px;
}

.marketplace_link {
    color: inherit;
}

.sale_end {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    align-items: center;
    gap: 30px;
}

.ape-container {
    display: flex;


    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 100vh;
}

.ape-left-container {
    max-width: 377px;
    width: 100%;
    height: fit-content;
    padding: 29px 40px 37px;
    border: 2px solid #000;
    box-sizing: border-box;
    box-shadow: 4.09722px 6.55556px 0 #000;
    background: #2c2d31;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.connectedWallet {
    background-color: #6667AB;

    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0px;
    text-align: center;
}

.ape-image img {
    width: 215px;
    height: 215px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
}

.ape-minted {
    margin-top: -12px;
    background: #212226;
    border-radius: 26.5203px;
    padding: 8px 17px;
    white-space: nowrap;

    font-weight: 400;
    font-size: 11.6689px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: .04em;
    color: #c5c7c9;
    text-transform: uppercase;
}

.balance {
    margin-top: 10px;
}
.desktopape{
    font-size: 12px ;
}

.balance-acquired {
    color: var(--primary);
    font-style: italic;
}

.gasfees {
    margin-top: 10px;
    font-size: 12px;
    justify-self: flex-end;
}
.gwei {
    font-weight: 800;
    letter-spacing: 1px;
    margin-left: 2px;
}

.ape-title {
    margin-top: 24px;
    padding-bottom: 24.5px;
    position: relative;

    font-weight: 400;
    font-size: 22px;
    line-height: 1.1;
    text-align: center;
    letter-spacing: .04em;
    text-transform: uppercase;
    color: #fff;
}

.ape-right-container {

    padding-left: 100px;
    min-height: 240px;




    display: flex;
    flex-direction: column;
    justify-content: center;



}

.ape-title-right {
    max-width: 470px;

    letter-spacing: 2px;
    font-weight: 800;
    font-size: 39.9084px;
    line-height: 1.25;
    color: #fff;
    margin-bottom: 20px;
}

.lightred {
    color: var(--primary);

}

.buy-section {

}

.ape-block {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding: 26px 37px;
    background: #2c2d31;
    border: 2px solid #000;
    box-shadow: 2px 3px 0 #000;
    border-radius: 40.9412px;


}

.mc-content__display {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mc-title {

    font-weight: 600;
    font-size: 22px;
    line-height: 1.18;
    padding-bottom: 10px;
    color: #fff;
}

.mc-value {

    font-weight: 400;
    font-size: 22px;
    line-height: 1.18;
    color: #979797;
}

.mc-value span {
    font-weight: 600;
    color: #f5e68e;
    white-space: nowrap;
}

.quantity {
    font-size: 2rem;

    height: 60px;
    width: 60px;
}

.connect_me {
    padding-top: 20px;
    width: 400px;
}

.connect_me_text {
    padding-bottom: 10px;
}

.connect_me_error {
    padding-top: 10px;
    font-size: 1rem;

}

.feedback {
    text-wrap: none;
    margin: 20px 0;

    width: 400px;

}

.market-link {
    color: white;
    text-decoration: underline;
}

.gotoMeta {
    display: none;
}

.hero_button {
    cursor: pointer;
}

@media (max-width: 820px) {
    .mint_container {

    height: inherit;

    }

    .freemint {
        margin-left: unset;
        width: 50%;
    }
    .freemintnote{
margin-top: 10px;
        line-height: 15px;
        text-align: justify;
    }
    .ape-container {

        flex-direction: column;
        gap: 30px;
        padding-top: 20px;

    }

    .connectedWallet {

        text-align: center;
        z-index: 10;

        font-weight: bold;


        padding: 10px;

        font-size: 16px;
    }

    .ape-left-container {

        padding: 40px;
        width: fit-content;
        font-size: 16px;
    }

    .ape-image img {
        width: 150px;
        height: 150px;

    }

    .ape-right-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: revert;
        min-height: 300px;
        text-align: center;
    }

    .ape-title-right {
        font-size: 30px;
        width: 50%;
        text-align: center;
    }

    .buy-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding-bottom: 55px;
    }

    .ape-block {

        max-width: fit-content;
        padding: 20px;
        font-size: 16px;
    }

    .mc-title {
        font-size: 18px;
        padding-bottom: 5px;
    }

    .feedback {
        font-size: 16px;
        padding: 10px 20px;
        margin: 0 20px;
        height: fit-content;
        line-height: 20px;
    }
    .connect_me_error {
        font-size: 16px;
        padding: 10px 20px;
        margin: 0 20px;
        text-align: left;
        line-height: 20px;
    }
    .sale_end {
        font-size: 16px;
        padding: 20px;
        text-align: center;
        line-height: 20px;
    }
}
