.community_container {
    padding: 20px;
}
.community_title{
    font-size: 2rem;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 10px;
    margin-bottom: 20px ;
}
.community_text {

    font-size: 1.2rem;
    line-height: 2rem;
    padding-top: 10px;
    text-align: justify;
}
.community-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.community_call {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 30px;
    text-align: justify;
}
.united-together {
    height: 250px;
    width: 250px;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
}
/* CSS */
.community_button {
    align-items: center;
    background-clip: padding-box;
    background-color: #fa6400;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    justify-content: center;
    line-height: 1.25;
    margin-top: 20px;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: 100%;
    height: fit-content;

}


.community_button:hover,
.community_button:focus {
    background-color: #fb8332;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.community_button:hover {
    transform: translateY(-1px);
}

.community_button:active {
    background-color: #c85000;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
}


@media (max-width: 820px) {

    .community_title{
        font-size: 24px;

    }
    .community-content {
        display: flex;
       flex-direction: column;
        gap: 30px;

    }
    .united-together {
        height: 320px;
        width: auto;
    }
    .community_call {
        flex-direction: column;
        gap: 30px;
    }
    .community_text {
        font-size: 16px;
        text-align: justify;
        width: 100%;

    }
    .community_button {
        width: 100%;
    }
}