
.specs_container {
    padding: 20px;

}
.specs_title {
    font-size: 2rem;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 10px;
    margin-bottom: 20px ;
}
.specs_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.specs_text {
    font-size: 1.2rem;
    line-height:2rem ;

    text-align: justify;
    flex-grow: 1;

}
.specs_img {
    height: 320px;
    align-self: center;
    justify-self: center;
    flex-grow: 1;
    border-radius: 10px;
}
@media (max-width: 820px) {
    .specs_title {
        font-size: 24px;

    }
    .specs_content {
        display: flex;
         flex-direction: column;
        gap: 30px;
    }
    .specs_text {
        font-size: 16px;
        line-height:2rem ;
        width: 100%;
        text-align: justify;

    }
    .specs_img {
        height: 320px;
        margin-bottom: 20px;

    }
}