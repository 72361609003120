.Header_container {

    display: flex;
    align-items: center;
    justify-content: flex-start;

    align-content: center;
    gap: 30px;


    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    background-color: var(--background);
    box-shadow: 0 32px 80px 14px rgba(0, 0, 0, 0.36), 0 0 0 1px rgba(0, 0, 0, 0.3);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;


}
.section-logo {
    flex-grow: 10;
    padding-left: 20px;
}
.logo_img {

    cursor: pointer;
    height: 40px;


}
.links {
    display: block;
    visibility: visible;
}
.section-link {

    user-select: none;
    cursor: pointer;
    font-weight: 400;
}
 .mint {

    color:   var(--primary);
     text-decoration: none;

     background-color: #1d2026;
     letter-spacing: 1px;
     padding-top: 3px;
     padding-right: 15px;
     padding-left: 15px;
     padding-bottom: 3px;
     border-radius: 5px;
}


.section-link:hover {
    color:   var(--primary);

}
.section-link:active {
   transform: translateY(1px);
}

.main-nav {
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    font-size: 16px;
    font-weight: 400;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    background: #175a76;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all .375s;
    box-shadow: 0 32px 80px 14px rgba(0, 0, 0, 0.36), 0 0 0 1px rgba(0, 0, 0, 0.3);
}

.main-nav.is-open {
    opacity: 1;
    z-index: 100;
    visibility: visible;
}

/* Yellow band effect */
.main-nav::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -15px;
    background: #1d2026 ;
    transform-origin: 0 0;
    transform: skew(-14deg) translateX(-100%);
    transition: all .275s .1s;
}

.main-nav.is-open::before {
    transform: skew(-16deg) translateX(0);
}

/* Skewing effect on menu links */
.main-nav ul {
    display: inline-flex;
    flex-direction: column;
    height: 93%; /* Should be 100%, but we have a notice message :D */
    align-items: flex-end;
    justify-content: center;
    transform: translateX(-18%) skew(-16deg);
}

.main-nav li {
    cursor: pointer;
    display: block;
    margin: .5rem 0;
    text-align: right;
    transform: skew(16deg);
}

/* Apparition effect on links */
.main-nav a {
    opacity: 0;
    transform: translateY(-10px);
}

.main-nav.is-open a {
    opacity: 1;
    transform: translateY(0);
}
.main-nav li:nth-child(1) a {
    transition: all 275ms 175ms
}
.main-nav li:nth-child(2) a {
    transition: all 275ms 225ms
}
.main-nav li:nth-child(3) a {
    transition: all 275ms 275ms
}
.main-nav li:nth-child(4) a {
    transition: all 275ms 325ms
}
.main-nav li:nth-child(5) a {
    transition: all 275ms 375ms
}


/* Decoration */
.main-nav ul,
.main-nav li {
    list-style: none;
    padding: 0;
}
.main-nav a {
    display: block;
    padding: 12px 0;
    color: var(--primary);
    font-weight: normal;
    text-decoration: none;

}

/* Burger Style: @see: https://codepen.io/CreativeJuiz/full/oMZNXy */
.open-main-nav {


    margin-top: 15px;
    z-index: 1000;
    background: none;
    border: 0;
    cursor: pointer;
}
.open-main-nav:focus {
    outline: none;
}
.burger {
    display: none;
    visibility : collapse;

    width: 28px;
    height: 4px;
    margin: 0 auto;
    background: var(--primary);

    transition: all .275s;
}

.burger:after,
.burger:before {
    content: '';
    display: block;
    height: 100%;
    background: var(--primary);
    transition: all .275s;
}

.burger:after {
    transform: translateY(-12px)    ;
}

.burger:before {
    transform: translateY(-16px)  ;
}

/* Toggle State part */
.is-open .burger {
    transform:   translateY(-12px) rotate(-45deg);
}

.is-open .burger:before {
    transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .burger:after {
    transform: translateY(-12px) translateX(10px) skew(-20deg);
    opacity: 0;
}



@media (max-width: 820px) {
    .Header_container {
        background-color: var(--background);
        box-shadow: 0 32px 80px 14px rgba(0, 0, 0, 0.36), 0 0 0 1px rgba(0, 0, 0, 0.3);
    }
    .section-link {
        font-size: 16px;
        background-color: #1d2026;
        letter-spacing: 1px;
        padding-top: 3px;
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 3px;
        border-radius: 5px;
        font-weight: 400;

    }
    .logo_img {

         margin-left: -20px;


    }
    .burger {
        display: block;
        visibility: visible;
        margin-right: 10px;
    }
    .links {
        display: none;
        visibility: hidden;
    }
}
