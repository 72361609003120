.homepage-container {



    padding-bottom: 20px;

}
.homepage-container-page {
    max-width: 1200px;
    margin: 0 auto;
}
@media (max-width: 820px) {
    .homepage-container {



        padding-bottom: 10px;

    }
}