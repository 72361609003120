.roadmap_container{
    padding: 20px;

 }

.roadmap_title {
    font-size: 2rem;
    padding-top: 10px;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 10px;
    margin-bottom: 40px ;
}
 blockquote.quote {
    position: relative;
    text-align: center;
    padding: 1rem 1.9rem;
    width: 90%;  /* create space for the quotes */
    color: #d2d2d2;
     font-size: 1.2rem;
     line-height: 20px;
    margin: 1rem auto 2rem;

}
 blockquote.EN {
    background:
            linear-gradient(to right, #039be5 4px, transparent 4px) 0 100%,
            linear-gradient(to left, #039be5 4px, transparent 4px) 100% 0,
            linear-gradient(to bottom, #039be5 4px, transparent 4px) 100% 0,
            linear-gradient(to top, #039be5 4px, transparent 4px) 0 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
}
/* -- create the quotation marks -- */
 blockquote.quote:before,
 blockquote.quote:after{
     font-family: FontAwesome, serif;
    position: absolute;
    color: #039be5;
    font-size: 34px;
}

 blockquote.EN:before{
    content: "\"";
    top: -12px;
    margin-right: -20px;
    right: 100%;
}
 blockquote.EN:after{
    content: "\"";
    margin-left: -20px;
    left: 100%;
    top: auto;
    bottom: -20px;
}
.stages_container {
    display: flex;

}
 .stages {

     font-size: 1.2rem;
     width: fit-content;
     display: grid;
     grid-template-columns: 1fr 4fr  ;
     grid-template-rows: repeat(6, 1fr);
     grid-column-gap: 0px;
     grid-row-gap: 30px;
     text-align: justify;

 }

.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 1 / 2 / 2 / 3; }
.div3 { grid-area: 2 / 1 / 3 / 2; }
.div4 { grid-area: 2 / 2 / 3 / 3; }
.div5 { grid-area: 3 / 1 / 4 / 2; }
.div6 { grid-area: 3 / 2 / 4 / 3; }
.div7 { grid-area: 4 / 1 / 5 / 2; }
.div8 { grid-area: 4 / 2 / 5 / 3; }
.div9 { grid-area: 5 / 1 / 6 / 2; }
.div10 { grid-area: 5 / 2 / 6 / 3; }
.div11 { grid-area: 6 / 1 / 7 / 2; }
.div12 { grid-area: 6 / 2 / 7 / 3; }



.stages_percentage {
     color : #fb8332;
    border-bottom: 1px solid #c85000;
    margin-right: 20px;
 }
.stages_text {
    line-height : 1.4rem;
}
.staged_img {
    height: 300px;

    margin-left: 20px;
}
@media (max-width: 820px) {
    .roadmap_title {
        font-size: 24px;}
    blockquote.quote {
        font-size: 16px;
    }
    .stages {


        font-size: 16px;
        display: flex;
        flex-direction: column;
        gap: 25px;

    }
    .staged_img {
        width: 100%;
        max-width: 350px;
        border-radius: 10px;
        margin-left: 0px;
    }

}
