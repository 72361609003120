.filter {
    position: absolute;
    top: 20px;
    left: 20px;

    z-index: 34456;
    background-color: #1d2026;

    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    overflow: scroll;
    height: 90vh;
}
.getTypesButton {
    width: 100%;
    margin-bottom: 10px;
}
.attr_container {

    text-align: left;
    padding: 10px;

}
.trait_value {
    font-size: 12px;
}
.variety_container {
   position: absolute;
    overflow: scroll;
    max-height: 90vh;
    right: 20px;
    top: 20px;
    background-color: #1d2026;
    padding: 20px;
}
.attr_container:hover {
    background-color: #4C4C4C;
    cursor: pointer;
}
.attr_type {


}